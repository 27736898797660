import React, {  useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AdminPage from "./components/admin-page/AdminPage";
import DisplayPage from "./components/display-page/DisplayPage";
import NotepadPage from "./components/notepad-page/NotepadPage";
import LoginPage from "./components/login-page/LoginPage";
import RequireAuth from "./auth/RequireAuth";
import Register from "./components/login-page/Register";

const App = () => {


  return (
      <Routes>
        <Route path="/">
          <Route index element={<NotepadPage />} />
          <Route path="AdminPage/Login" element={<LoginPage />} />
          <Route path="AdminPage/Register" element={<Register />} />
          <Route path="Display/:eventUrl" element={<DisplayPage />} />
          <Route path="Display" element={<DisplayPage />} />
          <Route element={<RequireAuth />}>
            <Route path="AdminPage/Dashboard" element={<AdminPage />} />
            <Route path="AdminPage/Dashboard/:eventUrl" element={<AdminPage />} />
          </Route>
          {/* <Route path="AdminPage" element={<AdminPage />} />*/}
          {/* default redirect to home page */}
          <Route path=":eventUrl" element={<NotepadPage />} />
        </Route>
      </Routes>
  );
}

export default App