import React from 'react';

const stroke={stroke: "white", strokeWidth:3};
const AlignCenter = () => {
  return (<svg height="28" width="28">
  <line x1="0" y1="2" x2="28" y2="2" style={stroke} />
  <line x1="6" y1="10" x2="28" y2="10" style={stroke} />
  <line x1="0" y1="18" x2="28" y2="18" style={stroke} />
  <line x1="6" y1="26" x2="28" y2="26" style={stroke} />
</svg>)}
export default AlignCenter;