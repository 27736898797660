import React, { useEffect }  from "react";
import axios from "axios";
import { baseURL } from "../../utilities/constant";
import drawOnCanvas from "../../utilities/drawOnCanvas";

const TileInfo = ({ id, index, tile, event, status, statusSelected, setUpdateUI, updateMode, setShowPopUpEdit }) => {

    useEffect(() => {
        initTile();
    }, []);

    const removeTile = () => {
      axios.delete(`${baseURL}/delete/${id}`).then((res) => {
        console.log(res);
        setUpdateUI((prevState) => !prevState);
      });
    };

    const updateStatusTile = (status) => {
        axios.put(`${baseURL}/update/${id}/${status}`, { status: status }).then((res) => {
            console.log(res.data);
            window.tilesArr[index].status=status;
            setUpdateUI((prevState) => !prevState);
        });
    };
    const onDelete=()=>{
        if (window.confirm("Do you want to delete this tile?")) {
            removeTile();
        }
    }
  
    const initTile=()=>{
        const image=drawOnCanvas(document.getElementById("drawCanvas"), tile);
        if(document.getElementById("thumbnail_"+index)){
            const thumbnail=document.getElementById("thumbnail_"+index);
            thumbnail.style.backgroundImage = "url('" + image + "')";
            thumbnail.style.backgroundColor = "white";
        };
    }
    let statusClass="p-2 ";
    switch(status.toLowerCase()) {
        case "received":
            statusClass=statusClass.concat("color-abt-gold");
            break;
        case "rejected":
            statusClass=statusClass.concat("color-abt-red");
            break;
        case "accepted":
            statusClass=statusClass.concat("color-abt-green");
            break;
        default:
            // code block
    }
    const editButtons=()=>{
        let buttons=null;
        let classNameButton="border-btn p-2";
        switch(status.toLowerCase()) {
            case "received":
                buttons=(<><span className={classNameButton+" blue-btn"} onClick={() => updateMode(index)} >View</span><span className={classNameButton+" green-btn"} onClick={() => updateStatusTile("accepted")} >Accept</span><span className={classNameButton+" blue-btn"} onClick={() => updateStatusTile("rejected")} >Reject</span></>);
                break;
            case "rejected":
                buttons=(<><span className={classNameButton+" blue-btn float-left"} onClick={() => updateMode(index)} >View</span><span className={classNameButton+" green-btn"} onClick={() => updateStatusTile("accepted")} >Accept</span><span className={classNameButton+" red-btn float-right"} onClick={onDelete} >Delete</span></>);
                break;
            case "accepted":
                buttons=(<><span className={classNameButton+" blue-btn float-left"} onClick={() => updateMode(index)} >View</span><span className={classNameButton+" blue-btn float-left"} onClick={() => updateStatusTile("rejected")} >Reject</span></>);
                break;
            default:
                buttons=(<span className={classNameButton+" blue-btn float-left"} onClick={() => updateMode(index)} >View</span>);
        }

        return (
            <>
            {buttons}
            </>
        );  

    }
    
    return (
        <li id={"tile-info-"+index} className={"flex flex-col select-none list-none grid-item tile-info tile-"+status.toLowerCase()}>
            <div className="border border-abt-blue-dark p-2 flex flex-col h-full"
                style={{borderRadius: "6px", backgroundColor:"white"}}>  
                <div className="p-2 color-abt-blue" style={{width:"150px",fontSize: "75%"}}>
                    {event}
                </div>
                <div id={"thumbnail_"+index}
                    className="image mx-auto"
                    style={{width:"100px",
                        height:"100px"
                    }}
                ></div>
                <div>
                    <span className="p-2 w-full">
                        {tile.split("|")[0]}
                    </span>
                </div>
                <div className="mt-auto">
                    <div className={statusClass} style={{width:"150px",fontSize: "75%"}}>
                        {status}
                    </div>
                    <div className="flex">
                        {editButtons()}
                    </div>
                </div>
            </div>
        </li>
    );
  };
  
  export default TileInfo;