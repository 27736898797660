import React, { useRef, useState, useEffect } from 'react';

import drawOnCanvas from "../../utilities/drawOnCanvas";
import preventDefault from '../../utilities/preventDefault';

/* //////////////////////////////////////////////////////////////////

    const [showPopUpEdit, setShowPopUpEdit] = useState(false);
    const [showAnother, setShowAnother] = useState(false);
    
    ... 

    const anotherFunction = (doFunction, isLabel, isInput, isTextDisplay)=>{
        // do somthing with the values
    }
    const onModalDragableClick = (doFunction, isLabel, isInput, isTextDisplay)=>{
        switch(doFunction) {
            case 'setOrgChartItemValue':
                setOrgChartItemValue(id, isLabel, isTextDisplay);
            case 'anotherFunction':
                anotherFunction(doFunction, isLabel, isInput, isTextDisplay);
        }
        // close all modals
        setShowPopUpEdit(false);
        setShowAnother(false);
    }

    ... 

            {showPopUpEdit ? <ModalTileEditor
                id={id}
                tile={tile}
                onModalDragableClick={onModalDragableClick}
            /> : null}


////////////////////////////////////////////////////////////////// */

const ModalTileEditor = ({index, onModalDragableClick }) => {

    const [pressed, setPressed] = useState(false);
    const [position, setPosition] = useState({x: ((window.innerWidth/2)-425), y: (window.innerHeight/2)-350});
    const popUpRef = useRef();
    const textAreaRef = useRef();
    const inputRef = useRef();

    // Monitor changes position state and update DOM
    useEffect(()=>{
        if (popUpRef.current) {
            popUpRef.current.style.transform = `translate(${position.x}px, ${position.y}px)`;
        };
    }, [position]);

    const onSetPressed = (isPressed)=>{
        // figure this out for dragging
        //  event.target.style.cursor = "grabbing";
        setPressed(isPressed);
    }
    // Update the current position if mouse is down
    const onMouseMove = (event)=>{
        if (pressed) {
            let myX=position.x + event.movementX;
            let myY=position.y + event.movementY;
            let mySum=0;
            if(window.innerWidth<Number(myX)+Number(popUpRef.current.clientWidth)){
                myX=Number(window.innerWidth)-Number(popUpRef.current.clientWidth);
                onSetPressed(false);
            }

            if(myX<1){
                myX=1;
                onSetPressed(false);
            } else {
                mySum=Number((window.innerWidth)+popUpRef.current.clientWidth);
                if(myX>mySum){
                    myX=mySum;
                    onSetPressed(false);
                }
            }
            if(myY<1){
                myY=1;
                onSetPressed(false);
            } else {
                mySum=Number((window.innerHeight)-popUpRef.current.clientHeight);
                if(myY>mySum){
                    myY=mySum;
                    onSetPressed(false);
                }
            }

            setPosition({
                x: myX,
                y: myY
            })
        }
    }
    
    const onCloseClick = ()=>{
        onModalDragableClick(false);
    };

    const onUpdateClick = ()=>{
        onModalDragableClick();
    };
    const imageUpdate=()=>{
        const image=drawOnCanvas(document.getElementById("drawCanvas"), window.tilesArr[index].tile);
        return( <div id="tileEditorImage"
            className="image mx-auto bg-white"
            style={{width:"800px",
                height:"800px",
                backgroundImage:"url('" + image + "')",
            }}
        ></div>);
    }

    return (
        <div onClick={onCloseClick} className="bg-gray-900 bg-opacity-40 fixed top-0 left-0 z-[1055] h-full w-full overflow-y-auto overflow-x-hidden outline-none flex flex-row justify-center">
            <div className="h-screen w-screen flex flex-col">
                <div id="textPopup" onClick={preventDefault} ref={ popUpRef }
                          onMouseMove={ onMouseMove }
                          onMouseDown={ () => onSetPressed(true) }
                          onMouseUp={ () => onSetPressed(false) }
                          className="bg-white rounded-xl max-w-[850px] drop-shadow-md">
                    <div onClick={onCloseClick} className="float-right text-2xl pr-2 select-none cursor-pointer text-blue-700">&times;</div>
                    <div id="dragHandle" className="cursor-pointer">
                        <div className="text-center p-1 pl-3 select-none text-gray-500 font-semibold">
                            Tile Editor
                        </div>
                        <hr className="h-px bg-gray-200 border-0 dark:bg-gray-700"></hr>
                    </div>
                    {imageUpdate()}
                    <div className="clear-both p-5">
                    {window.tilesArr[index].tile.split("|")[0]}
                    </div>
                    <div
                        onClick={onCloseClick}
                        type="button"
                        onMouseDown={ preventDefault }
                        className="float-right select-none cursor-pointer text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-1.5 text-center mr-2 mb-2">
                        Cancel
                    </div>
                    <div
                        onClick={onUpdateClick}
                        type="button"
                        onMouseDown={ preventDefault }
                        className="float-right select-none cursor-pointer text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-1.5 text-center mr-2 mb-2 me-md-2">
                        Save
                    </div>

                    <div className="clear-both"></div>
                </div>
            </div>
        </div>
    );
};
export default ModalTileEditor;


/*
    function getText(){
        return isText? (<>
            <div className="text-blue-400 font-bold select-none">{isLabel}</div>
                <p className="select-none">{isText}</p> 
            </>) : null;
    }
    function getTextArea(){
        return isTextDisplay? (<>
        <div className="text-blue-400 font-bold select-none">{isLabel}</div>
            <textarea
                ref={textAreaRef}
                rows="4"
                onMouseDown={ preventDefault }
                className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-yellow-500 focus:border-blue-500"
                placeholder=""
                defaultValue={isTextDisplay}>    
            </textarea>
        </>) : null;
    }
    function getInput(){
        return isInput? (<>
        <div className="text-blue-400 font-bold select-none">{tile}</div>
            <input
                ref={inputRef}
                type="text"
                className="position-absolute z-[100] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="isInput"/>   
        </>) : null;
    }
    */
