import scaleGetNumber from './scaleGetNumber';

export default function scaleImage() {
    const images = document.getElementsByClassName("image-scale");
    if(images.length>0){
        const imageWidth=186;
        const imageHeight=150;
        let ratioWidth=(window.innerWidth/2.5)/imageWidth;
        let ratioHeight=(window.innerHeight/2.5)/imageHeight;
        if(ratioHeight>ratioWidth){
            window.ratio=ratioWidth;
        } else {
            window.ratio=ratioHeight;
        }
        let myLeft=0;
        let myTop=0;
        let myWidth=0;
        let myHeight=0;
        let myFontSize=0;
        let myLetterSpacing=0;
        for(let i=0; i<images.length; i++){
            myLeft=scaleGetNumber(images[i].getAttribute('data-left'));
            myTop=scaleGetNumber(images[i].getAttribute('data-top'));
            myWidth=scaleGetNumber(images[i].getAttribute('data-width'));
            myHeight=scaleGetNumber(images[i].getAttribute('data-height'));
            myFontSize=scaleGetNumber(images[i].getAttribute('data-fontsize'));
            myLetterSpacing=scaleGetNumber(images[i].getAttribute('data-letterspacing'));
            images[i].style.left=myLeft+"px";
            images[i].style.top=myTop+"px";
            images[i].style.width=myWidth+"px";
            images[i].style.height=myHeight+"px";
            images[i].style.fontSize=myFontSize+"px";
            images[i].style.letterSpacing=myLetterSpacing+"px";
        }
    }
};