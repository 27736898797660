import React, { useEffect, useState } from "react";

import axios from "axios";

import {
  BrowserRouter as Router,
  useParams,
} from "react-router-dom";

import AnimationType from "./AnimationType";
import ModalTileEditor from "./ModalTileEditor";
import EventMenu from "./EventMenu";
import EventTitleBar from "./EventTitleBar";

import TileInfo from "./TileInfo";

import classAddToGroup from "../../utilities/classAddToGroup";
import classRemoveAll from "../../utilities/classRemoveAll";
import { baseURL } from "../../utilities/constant";
// import getEventFromUrl from "../../utilities/getEventFromUrl";
import TabButton from "../buttons/TabButton";

import './AdminPage.css';

const AdminPage = () => {
  const { eventUrl } = useParams();
  const [eventName, setEventName] = useState(eventUrl===undefined?window.allEvents:eventUrl);
  const [input, setInput] = useState("");
  const [tiles, setTiles] = useState([]);
  //const [timestamp, setTimestamp] = useState(new Date().getTime());
  const [updateUI, setUpdateUI] = useState(false);
  const [updateId, setUpdateId] = useState(null);
  const [showPopUpEdit, setShowPopUpEdit] = useState(null);
  let isEventPaused=false;

  
  //let eventId = eventUrl===undefined?"":eventUrl;

/*
testing tile:
  `NUMBER_AS_TEXT|auto,Arial,center,auto|1,1,799,1|799,1,799,799|1,799,799,799|1,1,1,799|1,1,799,799|1,799,799,1|`
  - This creates a tile with a number inside box a with diagnal lines
*/

  useEffect(() => {
    refreshTiles();
  }, [updateUI,eventName]);


  const refreshTiles=()=>{
    console.log("admin pages: Refresh tiles");
    let myID=eventName===""?window.allEvents:eventName;
    const myGet= "/get/"+myID+"/1";
    axios.get(`${baseURL}${myGet}`).then((res) => {
    //axios.get(`${baseURL}/get`).then((res) => {
      //console.log("res.data[0].status.toLowerCase()="+res.data[0].status.toLowerCase());
      isEventPaused=res.data[0].status.toLowerCase()==="paused"?true:false;
      window.animationType=res.data[0].animation===undefined?"full":res.data[0].animation.toLowerCase();
//console.log("adminPage: useEffect(): res.data="+res.data);
      //setTiles(res.data);
      initTiles(res.data.slice(1));
      setTabClass(window.adminTab);
      setEventPause();
      setAnimationType();
    });
  }
  const initTiles=(dataArr)=>{
    window.tilesArr=[];
    let i=0;
    let iCompare=0;
    let isGood=true;
    let tile=null;
    let event=null;
    for(i=0; i<dataArr.length; i++){
      isGood=true;
      tile=dataArr[i].tile;
      if(tile!=null){
        //if(dataArr[i].event === getEventFromUrl()||getEventFromUrl() === ""){
        if(dataArr[i].event === eventName||eventName === ""){
          window.tilesArr.push(dataArr[i]);
        }
      }
      
      event=dataArr[i].event;
      for(iCompare=0; iCompare<window.eventsArr.length; iCompare++){
        if(event===window.eventsArr[iCompare]){
          isGood=false;
        }
      }
      if(isGood){
        if(typeof event !== "undefined"&&event!==""&&event!=null){
          window.eventsArr.push(event);
        }
      }
      window.eventsArr=window.eventsArr.sort();
    }
    setTiles(dataArr);
  }

  const addTile = () => {
    //let myEvent = getEventFromUrl();
    axios.post(`${baseURL}/save`, { event:eventName, tile: input }).then((res) => {
      //console.log(res.data);
      setInput("");
      setUpdateUI((prevState) => !prevState);
    });
  };

  const updateMode = (index) => {
    setShowPopUpEdit(index);
  };

  const setEventPause=()=>{
    let element = document.getElementById("pause-play-switch");
    //console.log("isEventPaused="+isEventPaused);
    element.checked = isEventPaused?false:true;
  }
/*
  const setAnimationType=()=>{
    const elements = Array.from(document.getElementsByClassName('animation-btn'));
    elements.forEach(element => {
      if(element.id==="animation-"+animationType){
        element.classList.add('animation-btn-selected');
      } else {
        element.classList.remove('animation-btn-selected');
      }
    });
  }
*/
  const setAnimationType=()=>{
      const elements = Array.from(document.getElementsByClassName('animation-btn'));
      elements.forEach(element => {
          const animationArr = window.animationType.split(",");
          if(element.id==="animation-"+animationArr[0]){
              element.classList.add('animation-btn-selected');
          } else {
              element.classList.remove('animation-btn-selected');
          }
          if(animationArr.length>1){
              if(animationArr[1]==='grow'){
                  if(element.id==="animation-grow"){
                      element.classList.add('animation-btn-selected');
                  }
              }
          }
      });
  }

  const updateTile = () => {
    axios.put(`${baseURL}/update/${updateId}`, { event:eventName, tile: input }).then((res) => {
      //console.log(res.data);
      setUpdateUI((prevState) => !prevState);
      setUpdateId(null);
      setInput("");
    });
  };

  const getTabClass=(tab)=>{
    let addClass="";
    if(tab===window.adminTab){
      addClass=addClass+"tab-btn-selected";
    }
    return addClass;
  }
  
  const setTabClass=(tab)=>{
    window.adminTab=tab;
    classRemoveAll(null,"tab-btn-selected");
    let element = document.getElementById(tab+"Tab");
    element.classList.add("tab-btn-selected");
    if(tab!=="all"){
      classAddToGroup("tile-info", "hidden");
      classRemoveAll("tile-"+tab,"hidden");
    } else {
      classRemoveAll("tile-info", "hidden");
    }
  }
  const onModalDragableClick = (doFunction)=>{
    switch("onModalDragableClick("+doFunction+")") {
        case 'one':
          console.log("onModalDragableClick: one");
          break;
        case 'two':
          console.log("onModalDragableClick: two");
          break;
        default:
    }
    // close all modals
    setShowPopUpEdit(null);
}

  return (
    <>
      {showPopUpEdit!=null ? <ModalTileEditor
        index={showPopUpEdit}
        onModalDragableClick={onModalDragableClick}
      /> : null}
      <EventTitleBar eventName={eventName} setEventName={setEventName} refreshTiles={refreshTiles} />
      <EventMenu eventName={eventName} setEventName={setEventName} isEventPaused={isEventPaused} />
      <div className="container mx-auto">
          <div className="container">
            <div className="flex flex-row py-4">
              <AnimationType eventName={eventName} />
              <div className="basis-1/2" data="***TIMER GOES HERE***"></div>
            </div>
        </div>
      </div>
      <div className="mx-2">
        <div className="container mx-auto">
          <div className="container">
              <div className="input_holder clear-both hidden ">
                Create a tile from text:
                <input
                  type="text"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  value={input}
                  onChange={(event) => setInput(event.target.value) }
                />
                <button type="submit"
                  className="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800"
                  onClick={updateId ? updateTile : addTile}>
                  {updateId ? "Update Tile" : "Add Tile"}
                </button>
              </div>
              <TabButton
                  id="allTab"
                  onMouseUp={() => setTabClass("all")}
                  text="All Tiles"
                  addClass={getTabClass("all")} 
              />
              <TabButton
                  id="receivedTab"
                  onMouseUp={() => setTabClass("received")}
                  text="Received"
                  addClass={getTabClass("received")}
              />
              <TabButton
                  id="acceptedTab"
                  onMouseUp={() => setTabClass("accepted")}
                  text="Accepted"
                  addClass={getTabClass("accepted")}
              />
              <TabButton
                  id="rejectedTab"
                  onMouseUp={() => setTabClass("rejected")}
                  text="Rejected"
                  addClass={getTabClass("rejected")}
              />
              <div className="bg-abt-blue float-left min-w-full">
                <ul className="p-2 grid grid-cols-6">
                  {window.tilesArr.map((tile,index) => (
                    <TileInfo
                      key={tile._id}
                      id={tile._id}
                      index={index}
                      tile={tile.tile}
                      event={tile.event}
                      status={tile.status}
                      statusSelected={window.adminTab}
                      setUpdateUI={setUpdateUI}
                      updateMode={updateMode}
                      setShowPopUpEdit={setShowPopUpEdit}
                    />
                  ))}
                </ul>
              </div>
                <canvas
                  id="drawCanvas"
                      width="800px"
                      height="800px"
                      style={{ postion: "absolute", backgroundColor:"white", display: "none"}}
                />
          </div>
        </div>
      </div>
    </>
  )
}

export default AdminPage