export default function scaleCanvas() {
    const canvas = document.getElementById("drawCanvas");
    if(canvas!=null){
        let ratioWidth=((window.innerWidth-Number(canvas.offsetLeft))-30)/window.canvasSize;
        let ratioHeight=((window.innerHeight-Number(canvas.offsetTop))-15)/window.canvasSize;
        if(window.canvasSize*ratioHeight>(window.innerWidth-Number(canvas.offsetLeft))){
            window.ratio=ratioWidth;
        } else {
            window.ratio=ratioHeight;
        }
        canvas.style.width=window.canvasSize*window.ratio+"px";
        canvas.style.height=window.canvasSize*window.ratio+"px";
    }
};