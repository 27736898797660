import React from "react";
import axios from "axios";
import { baseURL } from "../../utilities/constant";

import animationFull from "../../assets/images/admin/animation-full.gif"; 
import animationQuick from "../../assets/images/admin/animation-quick.gif"; 
import animationNone from "../../assets/images/admin/animation-none.gif"; 
import animationGrow from "../../assets/images/admin/animation-grow.gif"; 


const AnimationType = ({ eventName }) => {
    let isEnabled = false;
    const animationUpdate = (animationNew) => {
        window.animationType=animationNew;
        axios.put(`${baseURL}/event/update/${eventName}`, { animation: window.animationType }).then((res) => {
            console.log(res.data);
            //setUpdateUI((prevState) => !prevState);
            //setUpdateId(null);
            //setInput("");
            setAnimationType();
        });
    };
    const growToggle = () =>{
        const animationArr = window.animationType.split(",");
        if(animationArr.length>1){
            if(animationArr[1]==='grow'){
                animationUpdate(animationArr[0]);
            } else {
                animationUpdate(animationArr[0]+",grow");
            }
        } else {
            animationUpdate(animationArr[0]+",grow");
        }
    }
    
    const setAnimationType=()=>{ 
        const elements = Array.from(document.getElementsByClassName('animation-btn'));
        elements.forEach(element => {
            const animationArr = window.animationType.split(",");
            if(element.id==="animation-"+animationArr[0]){
                element.classList.add('animation-btn-selected');
            } else {
                element.classList.remove('animation-btn-selected');
            }
            if(animationArr.length>1){
                if(animationArr[1]==='grow'){
                    if(element.id==="animation-grow"){
                        element.classList.add('animation-btn-selected');
                    }
                }
            }
        });
    }
    setAnimationType();
    const buttonClass="mx-auto pt-2 pl-2 color-abt-blue animation-btn";
    return (<>
            <div className="basis-1/2">
                <div className="flex flex-row">
                    <div id="animation-full" className={buttonClass}
                        onMouseUp={() => animationUpdate("full")} >
                        <img className="h-auto object-contain max-w-full"
                            src={animationFull} alt="Logo"/>
Full Animation
                    </div>
                    <div id="animation-quick" className={buttonClass}
                        onMouseUp={() => animationUpdate("quick")} >
                        <img className="h-auto object-contain max-w-full"
                            src={animationQuick} alt="Logo"/>
Quick Animation
                    </div>
                    <div id="animation-none" className={buttonClass}
                        onMouseUp={() => animationUpdate("none")} >
                        <img className="h-auto object-contain max-w-full"
                            src={animationNone} alt="Logo"/>
No Animation
                    </div>
                    <div id="animation-grow" className={buttonClass}
                        onMouseUp={() => growToggle()} >
                        <img className="h-auto object-contain max-w-full"
                            src={animationGrow} alt="Logo"/>
Grow Animation
                    </div>
                </div>
            </div>
    </>);
  };
  
  export default AnimationType;