import React from "react";
import axios from "axios";
import { baseURL } from "../../utilities/constant";


const EventMenu = ({ eventName, setEventName, isEventPaused }) => {
    let isEnabled = false;
    const toggleEnabled = () => {
        isEnabled=isEnabled?false:true;
        console.log("eventName="+eventName);
        console.log("isEnabled="+isEnabled);
        axios.put(`${baseURL}/event/update/${eventName}`, { status: isEnabled?"active":"paused" }).then((res) => {
            console.log(res.data);
            //setUpdateUI((prevState) => !prevState);
            //setUpdateId(null);
            //setInput("");
        });
    };
    return (<>
        <div className="w-full bg-abbott-gray color-abt-gray-dark">
            <div className="mx-2 flex">
                <div className="container flex mx-auto pt-2 pb-1">
                    <label className="switch">
                        <input id="pause-play-switch" type="checkbox" onClick={() => toggleEnabled()}/>
                        <span className="slider round"></span>
                    </label>
                    <span>&nbsp;Event enabled</span>
                </div>
                <div className="container flex mx-auto pt-2 pb-1">
                    <label className="switch">
                        <input type="checkbox"/>
                        <span className="slider round"></span>
                    </label>
                    <span>&nbsp;Show only accepted tiles during the event</span>
                </div>
            </div>
        </div>
    </>);
  };
  
  export default EventMenu;