import getNumberFromStyle from "./getNumberFromStyle";
import tileAnimationSort from "./tileAnimationSort";

export default function tileAnimation(tileAnimationArr){
    tileAnimationSort(null);
    //console.log("tileAnimation() test");
    const tilePaused=(item,step)=>{
      const foreTile = document.getElementById("foreTile_"+item);
      if(foreTile.style.opacity==="1"){      
        if(step>16*9){ // 9 seconds (16 a second)
          tileAnimationArr[item][1]=2; // next animation
          tileAnimationArr[item][2]=0; // reset steps
          //foreTile.style.zIndex = 20;
        };
      } else {
        foreTile.style.left = tileAnimationArr[item][3]+"px";
        foreTile.style.top = tileAnimationArr[item][4]+"px";
        foreTile.style.width = tileAnimationArr[item][5]+"px";
        foreTile.style.height = tileAnimationArr[item][5]+"px";
        foreTile.style.opacity=1;
      }
    }
    
    const tileToMask=(item,step)=>{
        const foreTile = document.getElementById("foreTile_"+item);
        const foreRect = foreTile===null?null:foreTile.getBoundingClientRect();
        const backTile = document.getElementById("backTile_"+tileAnimationArr[item][0]);
        //console.log("tileAnimationArr[item][0]="+tileAnimationArr[item][0]);
        const backRect = backTile===null?null:backTile.getBoundingClientRect();
        if(foreRect!==null&&backRect!==null){

          let sizeNew=(tileAnimationArr[item][5])*((100-step)*.01);
          let leftNew=0;
          let topNew=0;
          if((backRect.right-backRect.left)>(backRect.bottom-backRect.top)){
              topNew=backRect.top-(((backRect.right-backRect.left)-(backRect.bottom-backRect.top))/2);      
              topNew=(foreRect.top)-((foreRect.top-topNew)*((step)*.01));
              leftNew=(foreRect.left)-((foreRect.left-backRect.left)*((step)*.01));
              if(sizeNew<(backRect.right-backRect.left)){
                  tileAnimationArr[item][1]=3; // next animation
                  tileAnimationArr[item][2]=0; // reset steps   
              }
          } else {
              //works
              if((sizeNew<backRect.bottom-backRect.top)){
                  tileAnimationArr[item][1]=3; // next animation
                  tileAnimationArr[item][2]=0; // reset steps    
              }
              leftNew=backRect.left-(((backRect.bottom-backRect.top)-(backRect.right-backRect.left))/2);       
              leftNew=(foreRect.left)-((foreRect.left-leftNew)*((step)*.01));
              topNew=(foreRect.top)-((foreRect.top-backRect.top)*((step)*.01));
          }
          if(getNumberFromStyle(foreTile.style.width)>sizeNew){
            foreTile.style.width = sizeNew+"px";
            foreTile.style.height = sizeNew+"px";
            foreTile.style.left = leftNew+"px";
            foreTile.style.top = topNew+"px";
          }

        } else {
          //console.log("failed item="+item+", step="+step);
          //console.log("foreRect="+foreRect+", backRect="+backRect);
        }

    }
    const maskShow=(item)=>{
        const foreTile = document.getElementById("foreTile_"+item);
        const backTile = document.getElementById("backTile_"+tileAnimationArr[item][0]);
        tileAnimationArr[item][1]=4; // next animation
        tileAnimationArr[item][2]=0; // reset steps
        foreTile.style.opacity=0;
        backTile.style.opacity=1;
    }
    const highlightShow=(item)=>{
      const tileFore = document.getElementById("foreTile_"+item);
      const tileBack = document.getElementById("backTile_"+tileAnimationArr[item][0]);
      const tileForeWidth = getNumberFromStyle(tileFore.style.width);
      const tileBackWidth = getNumberFromStyle(tileBack.style.width);
      const tileBackHeight = getNumberFromStyle(tileBack.style.height);
      const sideSize=tileForeWidth+(tileForeWidth*.07);
      tileFore.style.width = (sideSize)+"px";
      tileFore.style.height = (sideSize)+"px";
      const backRect=tileBack.getBoundingClientRect();
      const leftValue=(backRect.left)-((sideSize-tileBackWidth)/2);
      tileFore.style.left = leftValue<10?10:leftValue+"px";
      tileFore.style.top = ((backRect.top)-((sideSize-tileBackHeight)/2))+"px";
      if(sideSize>(tileBackWidth>tileBackHeight?tileBackWidth:tileBackHeight)*3){
        tileAnimationArr[item][1]=6;
        tileAnimationArr[item][2]=0; // reset steps  
      }
    }
    const scaleDown=(item,step)=>{
      if(step>16*4){ // 9 seconds (16 a second)
        const tileFore = document.getElementById("foreTile_"+item);
        const tileBack = document.getElementById("backTile_"+tileAnimationArr[item][0]);
        const tileForeWidth = getNumberFromStyle(tileFore.style.width);
        const tileBackWidth = getNumberFromStyle(tileBack.style.width);
        const tileBackHeight = getNumberFromStyle(tileBack.style.height);
        const sideSize=tileForeWidth-(tileForeWidth*.07);
        tileFore.style.width = (sideSize)+"px";
        tileFore.style.height = (sideSize)+"px";
        const backRect=tileBack.getBoundingClientRect();
        const leftValue=((backRect.left)-((sideSize-tileBackWidth)/2));
        tileFore.style.left = leftValue<10?10:leftValue+"px";
        tileFore.style.top = ((backRect.top)-((sideSize-tileBackHeight)/2))+"px";
        if(sideSize<(tileBackWidth>tileBackHeight?tileBackWidth:tileBackHeight)){
          tileAnimationArr[item][1]=4; // next animation
          tileAnimationArr[item][2]=0; // reset steps
          tileFore.style.opacity=0;
          tileBack.style.opacity=1;
        }
      }
    }
    let step=0;
    let status=0;
    for(let i=0; i<tileAnimationArr.length; i++){
        status=tileAnimationArr[i][1];
        //console.log("status "+i+"="+status);
        if(status>0){
            step=tileAnimationArr[i][2]+1;
            tileAnimationArr[i][2]=step;
            switch(status) {
              case 1:
                tilePaused(i,step);
                break;
              case 2:
                // animating to mask
                tileToMask(i,step);
                break;
              case 3:
                maskShow(i,step);
                break;
              case 4:
                tileAnimationArr[i][0]=-1; // reset sequence
                tileAnimationArr[i][1]=-1; // reset sequence
                tileAnimationArr[i][2]=0; // reset steps
                break;
                case 5:
                  highlightShow(i);
                  break;
                case 6:
                  scaleDown(i,step);
                  break;
              default:
            }
        }
    }
    return tileAnimationArr;
}