import { useEffect, useRef } from 'react';
import unscaleGetNumber from '../../utilities/unscaleGetNumber';

export function useOnDraw(onDraw) {
    const canvasRef = useRef(null);
    const prevPointRef = useRef(null);
    const prevDirRef = useRef(null);
    const isDrawingRef = useRef(false);
    const points = useRef([]);

    const mouseMoveListenerRef = useRef(null);
    const mouseUpListenerRef = useRef(null);

    //const __changeDiff=4;

    useEffect(() => {

        function computePointInCanvas(clientX, clientY){
            if(canvasRef.current) {
                const boundingRect = canvasRef.current.getBoundingClientRect();
                return {
                    x : unscaleGetNumber(clientX - boundingRect.left),
                    y : unscaleGetNumber(clientY - boundingRect.top)
                }
            } else {
                return null;
            }
        }
        /*
        function getAngle(pointStart, pointEnd){
            // angle in radians
            const angleRads = Math.atan2(pointEnd.y - pointStart.y, pointEnd.x - pointStart.x);

            // angle in degrees
            //const angle = Math.atan2(pointEnd.y - pointStart.y, pointEnd.x - pointStart.x) * 180 / Math.PI;

            return angleRads*(180/Math.PI);

        }
        */
        function lineCleanUp(){
            if(isDrawingRef.current){
                let i=0;
                let pointsString=points.current[0].x+","+points.current[0].y+",";
                let pointsNew=[];
                const boundingRect = canvasRef.current.getBoundingClientRect();
                const canvasWidth = unscaleGetNumber(boundingRect.right-boundingRect.left);
                const canvasHeight = unscaleGetNumber(boundingRect.bottom-boundingRect.top);
                let doThis="save";
                // first point is good, check the rest
                // remove duplicates
                for(i=1; i<points.current.length; i++){
                    if(Math.abs(points.current[i-1].x - points.current[i].x)+Math.abs(points.current[i-1].y - points.current[i].y)>1){
                        pointsNew.push({x: points.current[i].x, y: points.current[i].y});
                    }
                }
                // check for points outside of the canvas
                for(i=1; i<pointsNew.length; i++){
                    doThis="save";
                    // check for points outside of the canvas
                    if(pointsNew[i].x<1){
                        if(pointsString.slice(-1)==="|"){
                            doThis="skip";
                        } else {
                            pointsNew[i].x=0;
                            doThis="add pipe";
                        }
                    } else {
                        if(pointsNew[i].x>canvasWidth){
                            if(pointsString.slice(-1)==="|"){
                                doThis="skip";
                            } else {
                                pointsNew[i].x=canvasWidth;
                                doThis="add pipe";
                            }
                        } else {
                            if(pointsNew[i].y<1){
                                if(pointsString.slice(-1)==="|"){
                                    doThis="skip";
                                } else {
                                    pointsNew[i].y=0;
                                    doThis="add pipe";
                                }
                            } else {
                                if(pointsNew[i].y>canvasHeight){
                                    if(pointsString.slice(-1)==="|"){
                                        doThis="skip";
                                    } else {
                                        pointsNew[i].y=canvasHeight;
                                        doThis="add pipe";
                                    }   
                                }
                            }
                        }
                    }

                    // check angle difference
                    /*
                    for(i=1; i<points.current.length; i++){
                        if(Math.abs(points.current[i-1].x - points.current[i].x)+Math.abs(points.current[i-1].y - points.current[i].y)>1){
                            pointsNew.push({x: points.current[i].x, y: points.current[i].y});
                        }
                    }
                    */

                    // check for similiar points
                    switch(doThis) {
                        case 'save':
                            pointsString+=pointsNew[i].x+","+pointsNew[i].y+",";
                        break;
                        case 'add pipe':
                            if(pointsString.slice(-1)!=="|"){
                                pointsString=pointsString.slice(0, -1);
                                pointsString+="|";
                            }
                        break;
                        default:
                            // do nothing
                    }
                }



                
                // check for similiar points

                //pointsString=pointsString.slice(0, -1);
                if(pointsString.slice(-1)!=="|"){
                    pointsString=pointsString.slice(0, -1);
                    pointsString+="|";
                }
                window.canvasData+=pointsString;
                points.current=[];
            }
        }

        function lineDraw(newX,newY){
            if(isDrawingRef.current){
                const point = computePointInCanvas(newX, newY);
                points.current.push({x: point.x,y: point.y}); 
                const ctx = canvasRef.current.getContext('2d');
                onUpdateCanvas(ctx, point);
            }
        }
        function onUpdateCanvas(ctx, point){
            if(onDraw) onDraw(ctx, point, prevPointRef.current);
            prevPointRef.current = point;
        }
        const getClientXY=(event)=>{
            if(isDrawingRef.current){
                let _clientX=0;
                let _clientY=0;
                if(!event.clientX){
                    // iPad
                    var lTouch = event.touches[0];
                    _clientX = lTouch.pageX;
                    _clientY = lTouch.pageY;
                } else{
                    _clientX = event.clientX;
                    _clientY = event.clientY;
                }
                lineDraw(_clientX,_clientY);
            }
        }
        function initMouseMoveListener(){
            const mouseMoveListener = (event) => {
                event.preventDefault();
                event.stopPropagation();
                getClientXY(event);
            }
            mouseMoveListenerRef.current = mouseMoveListener;
            window.addEventListener('mousemove', mouseMoveListener);
            window.addEventListener('touchmove', mouseMoveListener);
        }

        function initMouseUpListener(){
            const listener = (event) => {
                event.preventDefault();
                event.stopPropagation();
                if(isDrawingRef.current){
                    if(event.clientX!==undefined&&event.clientY!==undefined){
                        lineDraw(event.clientX,event.clientY);
                    }
                    lineCleanUp();
                }
                isDrawingRef.current = false;
                prevPointRef.current = null;
                prevDirRef.current = null;
//console.log("window.canvasData="+window.canvasData);
//const canvas = canvasRef.current;
//console.log("canvas.toDataURL()="+canvas.toDataURL());
            }
            mouseUpListenerRef.current = listener;
            window.addEventListener("mouseup", listener);
            window.addEventListener("touchend", listener);
        }

        function removeListeners(){
            if(mouseMoveListenerRef.current){
                window.removeEventListener("mousemove", mouseMoveListenerRef.current);
                window.removeEventListener("touchmove", mouseMoveListenerRef.current);
            }
            if(mouseUpListenerRef.current){
                window.removeEventListener("mouseup", mouseUpListenerRef.current);
                window.removeEventListener("touchend", mouseUpListenerRef.current);
            }
        }
        initMouseMoveListener();
        initMouseUpListener();
        return () => {
            removeListeners();
        }
    }, [onDraw]);

    function setCanvasRef(ref){
        canvasRef.current = ref;
    }

    function onMouseDown(event){
        event.stopPropagation();
        isDrawingRef.current = true;
    }

    return{
        setCanvasRef,
        onMouseDown
    }
};