import {useRef, useState, useEffect } from "react";

import axios from '../../utilities/axios';

const USER_REGEX = /^[A-z][A-z0-9-_]{3,23}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const REGISTER_URL = '/register';

const Register=()=>{
    const userRef = useRef();
    const errRef = useRef();

    const [user, setUser] = useState('');
    const [validName, setValidName] = useState(false);
    const [userFocus, setUserFocus] = useState(false);

    const [pwd, setPwd] = useState('');
    const [validPwd, setValidPwd] = useState(false);
    const [pwdFocus, setPwdFocus] = useState(false);

    const [matchPwd, setMatchPwd] = useState('');
    const [validMatch, setValidMatch] = useState(false);
    const [matchFocus, setMatchFocus] = useState(false);

    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        userRef.current.focus();
    }, [])

    useEffect(() => {
        setValidName(USER_REGEX.test(user));
    }, [user])

    useEffect(() => {
        setValidPwd(PWD_REGEX.test(pwd));
        setValidMatch(pwd === matchPwd);
    }, [pwd, matchPwd])

    useEffect(() => {
        setErrMsg('');
    }, [user, pwd, matchPwd])

    const handleSubmit = async (e) => {
        e.preventDefault();
        // if button enabled with JS hack
        const v1 = USER_REGEX.test(user);
        const v2 = PWD_REGEX.test(pwd);
        if (!v1 || !v2) {
            setErrMsg("Invalid Entry");
            return;
        }
        try {
            const response = await axios.post(REGISTER_URL,
                JSON.stringify({ user, pwd }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );
            // TODO: remove console.logs before deployment
            console.log(JSON.stringify(response?.data));
            //console.log(JSON.stringify(response))
            setSuccess(true);
            //clear state and controlled inputs
            setUser('');
            setPwd('');
            setMatchPwd('');
        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 409) {
                setErrMsg('Username Taken');
            } else {
                setErrMsg('Registration Failed')
            }
            errRef.current.focus();
        }
    }

    const labelClass="block text-sm font-bold mb-1 color-abt-blue";
    const inputClass="shadow appearance-none border rounded w-4/5 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline";
    const alertClass="bg-abt-blue-dark text-xs color-abt-gray p-3 rounded max-w-lg";
    const errorClass="bg-abt-red-50 color-abt-red text-xs p-3 w-full max-w-lg font-semibold rounded";
    const buttonClass="bg-transparent font-semibold py-2 px-4 border rounded";
    const enabledClass="hover:border-transparent hover:text-white";
    const disabledClass="border-abt-gray color-abt-gray";
    return (
        <>
            {success ? (
                <section className="flex flex-col min-h-screen justify-center item-center bg-abt-green text-white pt-10">
                    <div className="text-5xl font-semibold">Success!</div>
                    <div>
                        <a href="#">Sign In</a>
                    </div>
                </section>
            ) : (
                <section className="flex flex-row min-h-screen justify-center bg-abbott-gray pt-10">
                    <div className="flex flex-col w-full max-w-lg">
                        <div className="w-full">
                            <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8">
                                <div className="px-8 text-center text-xl font-semibold mb-6 color-abt-blue-dark">Register</div>
                                <div className="mb-1">
                                    <label className={labelClass} htmlFor="username">
                                        Username:&nbsp;
                                    </label>
                                </div>
                                <input
                                    type="text"
                                    id="username"
                                    ref={userRef}
                                    autoComplete="off"
                                    className={inputClass}
                                    onChange={(e)=>setUser(e.target.value)}
                                    required
                                    aria-invalid={validName ? "false" : "true"}
                                    aria-describedby="uidnote"
                                    onFocus={()=>setUserFocus(true)}
                                    onBlur={()=>setUserFocus(false)}
                                />
                                <span className={ validName ? "text-green-500 inline" : "hidden"}>&nbsp;&#10004;</span>
                                <span className={ !validName || !user ? "text-red-500 inline font-bold" : "hidden"}>&nbsp;&times;</span>

                                <div className="mt-5 mb-1">
                                    <label className={labelClass} htmlFor="password">
                                        Password:&nbsp;
                                    </label>
                                </div>
                                <input
                                    type="password"
                                    id="password"
                                    className={inputClass}
                                    onChange={(e) => setPwd(e.target.value)}
                                    value={pwd}
                                    required
                                    aria-invalid={validPwd ? "false" : "true"}
                                    aria-describedby="pwdnote"
                                    onFocus={() => setPwdFocus(true)}
                                    onBlur={() => setPwdFocus(false)}
                                />
                                <span className={ validPwd ? "text-green-500 inline" : "hidden"}>&nbsp;&#10004;</span>
                                <span className={ validPwd || !pwd ?  "hidden": "text-red-500 inline font-bold"}>&nbsp;&times;</span>

                                <div className="mt-5 mb-1">
                                    <label className={labelClass} htmlFor="confirm_pwd">
                                        Confirm Password:
                                    </label>
                                </div>
                                <input
                                    type="password"
                                    id="confirm_pwd"
                                    className={inputClass}
                                    onChange={(e) => setMatchPwd(e.target.value)}
                                    value={matchPwd}
                                    required
                                    aria-invalid={validMatch ? "false" : "true"}
                                    aria-describedby="confirmnote"
                                    onFocus={() => setMatchFocus(true)}
                                    onBlur={() => setMatchFocus(false)}
                                />
                                <span className={ validMatch && matchPwd ? "text-green-500 inline" : "hidden"}>&nbsp;&#10004;</span>
                                <span className={ validMatch || !matchPwd ? "hidden" : "text-red-500 inline font-bold"}>&nbsp;&times;</span>
                                <br/><br/>
                                <button disabled={!validName || !validPwd || !validMatch ? true : false}
                                    className={!validName || !validPwd || !validMatch ? buttonClass+" "+disabledClass  : buttonClass+" "+enabledClass+" button-abt-blue"}>Sign Up</button>
                            </form>
                        </div>
                        <div ref={errRef} className={errMsg ? "block "+errorClass : "hidden"} aria-live="assertive"><div className="character-icon bg-abt-red text-white">!</div>{errMsg}</div>
                        <div id="uidnote" className={userFocus && user && !validName ? "block "+alertClass : "hidden"}>
                            4 to 24 characters.<br/>
                            Must begin with a letter.<br/>
                            Letters, Numbers, underscores, hyphens allowed.
                        </div>
                        <div id="pwdnote" className={pwdFocus && !validPwd ? "block "+alertClass : "hidden"}>
                            8 to 24 characters.<br />
                            Must include uppercase and lowercase letters, a number and a special character.<br />
                            Allowed special characters: <span aria-label="exclamation mark">!</span> <span aria-label="at symbol">@</span> <span aria-label="hashtag">#</span> <span aria-label="dollar sign">$</span> <span aria-label="percent">%</span>
                        </div>
                        <div id="confirmnote" className={matchFocus && !validMatch ? "block "+alertClass : "hidden"}>
                            Must match the first password input field.
                        </div>
                        <br/><br/>
                        {/*
                        <div className="bg-white shadow-md rounded px-8 pt-6 pb-8">
                            <div className="px-8 text-center text-xl font-semibold mb-6 color-abt-blue-dark">Already Registered?</div>
                            <span className={buttonClass+" "+enabledClass+" button-abt-blue"}>
                                // {put router link here}
                                <a href="/Login">Sign In</a>
                            </span>
                        </div>
                        */}
                    </div>
                </section>
            )}
        </>
    );
}

export default Register;