import React from 'react';
import preventDefault from '../../utilities/preventDefault';
import drawSuccess from "../../assets/images/notepad/draw-success.png";

const Submitted = () => {
    return (<>
        <div id="Submitted"
            className="modal"
            style={{display:"none"}}
            onClick={preventDefault}
        >
            <div className="spinner"></div>
            <div id="Success"
                style={{display:"none"}}
                className="w-full h-full bg-green-lite success">
                    <img src={drawSuccess} alt="Success"/>
                    <p>Submitted</p>
            </div>
        </div>
    </>);
}
export default Submitted;