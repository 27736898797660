import React from "react";

const TabButton = ({id, text, onMouseUp, addClass}) => {
// export default ({id, text, onMouseUp, addClass}) => {
  const className="font-semibold float-left tab-btn "+addClass;
  return (
    <div id={id} className={className} onMouseUp={onMouseUp}>
        {text}
    </div>
  )
}
export default TabButton;