import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { AuthProvider } from './auth/AuthProvider';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
if(!window.tilesArr){
  window.tilesArr=[];
}
if(!window.eventsArr){
  window.eventsArr=[];
}
if(!window.ratio){
  window.ratio=1;
}
if(!window.itemDelimiter){
  window.itemDelimiter="!!item-deleimiter!!";
}
if(!window.canvasSize){
  window.canvasSize=800;
}
if(!window.canvasData){
  window.canvasData="|auto,Arial,left,auto|"; // this is a string for a card: "TEXT|FONT,FONT_SIZE,TEXT_ALIGN,FONT_COLOR|x1,y1,x2,y2,x3,y3|x1,y1,x2,y2,x3,y3" 
}
if(!window.userCount){
  window.userCount=40; // minimum number of users is 39
}
if(!window.allEvents){
  window.allEvents="ALL_EVENTS"; // minimum number of users is 39
}
if(!window.adminTab){
  window.adminTab="all"; // minimum number of users is 39
}
if(!window.animationType){
  window.animationType="full"; // minimum number of users is 39
}

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route path="/*" element={<App />} />
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>
);