import React, { useEffect } from 'react';

import scaleCanvas from '../../utilities/scaleCanvas';
import scaleGetNumber from '../../utilities/scaleGetNumber';
import { useOnDraw } from './Hooks';

const Canvas = () => {

    useEffect(() => {
        scaleCanvas();
        scaleCanvas(); // needs to scale twice the first time
    });

    const {
        onMouseDown,
        setCanvasRef
    } = useOnDraw(onDraw);

    function drawLine(start, end, ctx, color, lineWidth){
        start = start ?? end;
        ctx.beginPath();
        ctx.lineWidth = lineWidth;
        ctx.strokeStyle = color;
        ctx.moveTo(start.x, start.y);
        ctx.lineTo(end.x, end.y);
        ctx.stroke();

        ctx.fillStyle = color;
        ctx.beginPath();
        ctx.arc(end.x, end.y, lineWidth/2, 0, lineWidth/2 * Math.PI);
        ctx.fill();

        ctx.lineCap = 'round';
        ctx.lineJoin = 'round';
    }

    function onDraw(ctx, point, prevPoint){
        drawLine(prevPoint, point, ctx, "#000", 2);
    }
    return(<>
        <canvas
        id="drawCanvas"
            width={scaleGetNumber(window.canvasSize)}
            height={scaleGetNumber(window.canvasSize)}
            onMouseDown={onMouseDown}
            onTouchStart={onMouseDown}
            ref={setCanvasRef}
            className="bg-white"
        />
    </>);
}

export default Canvas;