import { useRef, useState, useEffect } from 'react';
import useAuth from "../../auth/useAuth";
import { useNavigate, useLocation } from 'react-router-dom';

import axios from '../../utilities/axios';

import "./LoginPage.css";

const LOGIN_URL = '/auth';

const LoginPage = () => {
    const { setAuth } = useAuth();

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";

    const userRef = useRef();
    const errRef = useRef();

    const [user, setUser] = useState('');
    const [pwd, setPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');

    useEffect(() => {
        userRef.current.focus();
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [user, pwd])

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(LOGIN_URL,
                JSON.stringify({ user, pwd }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );
            //console.log(JSON.stringify(response?.data));
            //console.log(JSON.stringify(response));
            const accessToken = response?.data?.accessToken;
            const roles = response?.data?.roles;
            setAuth({ user, pwd, roles, accessToken });
            setUser('');
            setPwd('');
            navigate(from, { replace: true });
        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 400) {
                setErrMsg('Incorrect Username or Password');
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized');
            } else {
                setErrMsg('Login Failed');
            }
            errRef.current.focus();
        }
    }

    const labelClass="block text-sm font-bold mb-1 color-abt-blue";
    const inputClass="shadow appearance-none border rounded w-4/5 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline";
    //const alertClass="bg-abt-blue-dark text-xs color-abt-gray p-3 rounded max-w-lg";
    const errorClass="bg-abt-red-50 color-abt-red text-xs p-3 w-full max-w-lg font-semibold rounded";
    const buttonClass="bg-transparent font-semibold py-2 px-4 border rounded hover:border-transparent hover:text-white";

    return (
        <>
            <section className="flex flex-row min-h-screen justify-center bg-abbott-gray pt-10">
                <div className="flex flex-col w-full max-w-lg">
                    <div className="w-full">
                        <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8">
                            <div className="px-8 text-center text-xl font-semibold mb-6 color-abt-blue-dark">Sign In</div>
                            <div className="mb-1">
                                <label className={labelClass} htmlFor="username">
                                    Username:&nbsp;
                                </label>   
                            </div>
                            <input
                                type="text"
                                id="username"
                                ref={userRef}
                                autoComplete="off"
                                className={inputClass}
                                onChange={(e)=>setUser(e.target.value)}
                                value={user}
                                required
                            />
                            <div className="mt-5 mb-1">
                                <label className={labelClass} htmlFor="password">
                                    Password:&nbsp;
                                </label>
                            </div>
                            <input
                                type="password"
                                id="password"
                                className={inputClass}
                                onChange={(e) => setPwd(e.target.value)}
                                value={pwd}
                                required
                            />
                            <br/><br/>
                            <button
                                className={buttonClass+" button-abt-blue"}>Sign In</button>
                        </form>
                    </div>
                    <div ref={errRef} className={errMsg ? "block "+errorClass : "hidden"} aria-live="assertive"><div className="character-icon bg-abt-red text-white">!</div>{errMsg}</div>
                    <br/><br/>
{/*
                    <div className="bg-white shadow-md rounded px-8 pt-6 pb-8">
                        <div className="px-8 text-center text-xl font-semibold mb-6 color-abt-blue-dark">Need an Account?</div>
                        <span className={buttonClass+" "+"button-abt-blue"}>
                            <a href="/Register">Sign Up</a>
                        </span>
                    </div>
*/}
                </div>
            </section>
        </>
    )
}

export default LoginPage