export default function scaleTilesFore(tileAnimationArr) {
    let tileSize=0;
    if(window.innerWidth*.3>window.innerHeight*.46){
      tileSize=window.innerHeight*.46;
    } else {
      tileSize=window.innerWidth*.3;
    }

    const marginLeft= (window.innerWidth-(tileSize*3))/4;
    const marginTop= (window.innerHeight-(tileSize*2))/3;

    if(tileAnimationArr===null){
      tileAnimationArr=[];
      tileAnimationArr.push([-1,-1,0,marginLeft,marginTop,tileSize]);
      tileAnimationArr.push([-1,-1,0,((marginLeft*2)+tileSize),marginTop,tileSize]);
      tileAnimationArr.push([-1,-1,0,((marginLeft*3)+(tileSize*2)),marginTop,tileSize]);
      tileAnimationArr.push([-1,-1,0,marginLeft,(marginTop*2)+tileSize,tileSize]);
      tileAnimationArr.push([-1,-1,0,((marginLeft*2)+tileSize),(marginTop*2)+tileSize,tileSize]);
      tileAnimationArr.push([-1,-1,0,((marginLeft*3)+(tileSize*2)),(marginTop*2)+tileSize,tileSize]);
    } else {
      
      tileAnimationArr[0][3]=marginLeft;
      tileAnimationArr[0][4]=marginTop;
      tileAnimationArr[0][5]=tileSize;
      
      tileAnimationArr[1][3]=((marginLeft*2)+tileSize);
      tileAnimationArr[1][4]=marginTop;
      tileAnimationArr[1][5]=tileSize;
      
      tileAnimationArr[2][3]=((marginLeft*3)+(tileSize*2));
      tileAnimationArr[2][4]=marginTop;
      tileAnimationArr[2][5]=tileSize;
      
      tileAnimationArr[3][3]=marginLeft;
      tileAnimationArr[3][4]=(marginTop*2)+tileSize;
      tileAnimationArr[3][5]=tileSize;
      
      tileAnimationArr[4][3]=((marginLeft*2)+tileSize);
      tileAnimationArr[4][4]=(marginTop*2)+tileSize;
      tileAnimationArr[4][5]=tileSize;
      
      tileAnimationArr[5][3]=((marginLeft*3)+(tileSize*2));
      tileAnimationArr[5][4]=(marginTop*2)+tileSize;
      tileAnimationArr[5][5]=tileSize;
      
    }
    return tileAnimationArr;
};