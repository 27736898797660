// EXAMPLE OF USE: 
//      import drawOnCanvas from "../../Utilities/drawOnCanvas";
//      const canvasData="TEXT|FONT,FONT_SIZE,TEXT_ALIGN,FONT_COLOR|x1,y1,x2,y2,x3,y3|x1,y1,x2,y2,x3,y3";
//      drawOnCanvas(document.getElementById("drawCanvas"), canvasData);
export default function drawOnCanvas(canvas, canvasData) {
    canvasData=canvasData===undefined?window.canvasData:canvasData;
    const fontFamilyArr=["Arial,Sans-serif","Brush Script MT,Cursive","Times-Roman,Serif"];
    const drawLine=(start, end, ctx, color, lineWidth)=>{
        start = start ?? end;
        ctx.beginPath();
        ctx.lineWidth = lineWidth;
        ctx.strokeStyle = color;
        ctx.moveTo(start.x, start.y);
        ctx.lineTo(end.x, end.y);
        ctx.stroke();

        ctx.fillStyle = color;
        ctx.beginPath();
        ctx.arc(end.x, end.y, lineWidth/2, 0, lineWidth/2 * Math.PI);
        ctx.fill();

        ctx.lineCap = 'round';
        ctx.lineJoin = 'round';
    }
    const onDraw=(ctx, point, prevPoint)=>{
        drawLine(prevPoint, point, ctx, "#000", 2);
    }

    const onDrawPoints=(ctx)=>{
        let iLine=3;
        let iPoint=1;
        const linesArray=canvasData.split("|");
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        let lineNew="";
        for(iLine=2; iLine<linesArray.length; iLine++){ // 0 and 1 are for text
            lineNew=linesArray[iLine].split(",");
            for(iPoint=0; iPoint<lineNew.length; iPoint++){ // 0-1 is the first line
                onDraw(ctx, {x: lineNew[iPoint],y:lineNew[iPoint+1]}, {x: lineNew[iPoint-2],y:lineNew[iPoint-1]});
                iPoint++; // adding 1 for the y
            }
        }
    }
    const onCreateRows=(text)=>{
        let rowCharMax=text.length/6<12?12:text.length/6;
        let textArr=text.split(" ");
        let textRowArr=[[textArr[0]]];
        let rowNum=0;
        let rowCharCount=textRowArr[0][0].length;
        let i=0;
        if(textArr.length>1){
            for (i=1; i<textArr.length; i++){
                rowCharCount+=textArr[i].length;
                if(rowCharCount>rowCharMax){
                    rowNum++;
                    textRowArr.push([textArr[i]]);
                    rowCharCount=textRowArr[rowNum][0].length;
                } else {
                    textRowArr[rowNum].push(textArr[i]);
                }
            }
        }
        return textRowArr;
    }
    const textWidth=(ctx, text)=>{
        const linesArray=canvasData.split("|");
        const textStyles = linesArray[1].split(",");
        ctx.font = "100px"+textStyles[1];
        var metrics = ctx.measureText(text);
        var width = metrics.width;
        return width;

    }
    const onDrawText=(ctx)=>{
        const linesArray=canvasData.split("|");
        const textStyles = linesArray[1].split(",");
        // 800 with padding of 25 on all sides = 750
        // 750/6 = 125 font for 6 rows
        // 
        const canvasSize=750;
        let margin = 25;
        let top=margin;
        let left=margin; // dynamic for aligning center or right
        let fontSize=100;
        let text=linesArray[0];
        text=text.split(window.itemDelimiter).join("|");
        let textRowArr=[];

        textRowArr = onCreateRows(text);
        ctx.fillStyle = "black";
        ctx.font = (fontSize)+"px "+fontFamilyArr[textStyles[1]];
        let widthLargest=textWidth(ctx,textRowArr[0]);
        let widthTest=0;
        for (let i=1; i<textRowArr.length; i++){
            widthTest=textWidth(ctx,textRowArr[i]);    
            widthLargest=widthLargest<widthTest?widthTest:widthLargest;
        }
        if(widthLargest>700){
            fontSize=90;
        }
        if(widthLargest>800){
            fontSize=80;
        }
        if(widthLargest>850){
            fontSize=70;
        }
        if(widthLargest>900){
            fontSize=60;
        }
        if(widthLargest>1150){
            fontSize=50;
        }
        if(widthLargest>1300){
            fontSize=40;
        }
        fontSize=textRowArr.length>7?40:fontSize;
        ctx.font = (fontSize)+"px "+textStyles[1];
        top=((canvasSize-(textRowArr.length*fontSize))/2)+fontSize;
        for (let i=0; i<textRowArr.length; i++){
            text = textRowArr[i].join(" ");
            // left = (((canvasSize - ctx.measureText(text).width)/2)+margin);
            switch(textStyles[2]){
                case "center":
                    left = (((canvasSize - ctx.measureText(text).width)/2)+margin);
                    break;   
                case "right":
                    left = (((canvasSize - ctx.measureText(text).width)));
                    break;    
                default:
                    left = margin;
            }
            ctx.fillText(text, left, top);
            top=top+fontSize;
        }
//console.log("text="+text+", left="+left+", top="+top+", fontSize:"+fontSize+" , textRowArr.length="+textRowArr.length);
    }
    const onCreate=()=>{
        const ctx = canvas.getContext('2d');
        onDrawPoints(ctx);
        onDrawText(ctx);
        return canvas.toDataURL();
    }
    return canvas?onCreate():null;
}