import getRandom from "./getRandom";

const getPosition = () =>{
    let horizontal=["left","right","center"];
    let vertical=["top","bottom","center"];
    return getRandom(horizontal)+" "+getRandom(vertical);
}
export default function tileMaskBuild(count){
    let returnArray=[];
    const userCount=count+1;
    const tileArea = 39/userCount;
    const tileDepth = 26;
    const gapPadding=1; // +1 to allow overlap(hide gaps)
    let i=0;
    // Start from the inside, so the longest(top) line gets any leftovers
    // Middle
    let tileCount = Math.floor(7/tileArea);
    let tileWidth = (114/tileCount)+gapPadding;
    let tileHeight = tileDepth;
    let posY=50;
    let posX=137-tileWidth+gapPadding;
    for(i=0; i<tileCount-1; i++){
      if(posX<21){
        returnArray.unshift([posX,posY,tileWidth,tileHeight,"right top",0]);
      } else {
        returnArray.unshift([posX,posY,tileWidth,tileHeight,getPosition(),0]);
      }
      posX=posX-tileWidth+gapPadding;
    }
    returnArray.unshift([25,posY,(posX+tileWidth)-25,tileDepth+8,"center top",0]);
    // Left
    tileCount = Math.floor(4/tileArea);
    tileWidth = tileDepth+gapPadding;
    tileHeight = 75/tileCount;
    posY=50;
    posX=0;
    for(i=0; i<tileCount-1; i++){
      if(posY<71){
        returnArray.unshift([posX,posY,tileWidth,tileHeight,"right bottom",0]);
      } else {
        returnArray.unshift([posX,posY,tileWidth,tileHeight,getPosition(),0]);
      }
      posY=posY+tileHeight;
    }
    returnArray.unshift([posX,posY,tileDepth+8,124-(posY),"left top",0]);
    // Bottom
    tileCount = Math.floor(10/tileArea);
    tileWidth = 162/tileCount+gapPadding;
    tileHeight = tileDepth;
    posY=123;
    posX=0;
    for(i=0; i<tileCount-1; i++){
      if(posX<32){
        returnArray.unshift([posX,posY,tileWidth,tileHeight,"right top",0]);
      } else {
        returnArray.unshift([posX,posY,tileWidth,tileHeight,getPosition(),0]);
      }
      posX=posX+tileWidth-gapPadding;
    }
    returnArray.unshift([posX,posY-6,161-(posX),tileDepth+6,"center bottom",0]);
    // Right
    tileCount = Math.floor(7/tileArea);
    tileWidth = tileDepth+gapPadding;
    tileHeight = 125/tileCount;
    posY=150-tileHeight;
    posX=161;
    for(i=0; i<tileCount-1; i++){
      if(posY>130){
        returnArray.unshift([posX,posY,tileWidth,tileHeight,"left top",0]);
      } else {
        returnArray.unshift([posX,posY,tileWidth,tileHeight,getPosition(),0]);
      }
      posY=posY-tileHeight;
    }
    returnArray.unshift([posX-8,posY,tileDepth+8,tileHeight,"right center",0]);
    // Top
    tileCount = userCount-returnArray.length-1;
    tileWidth = 187/tileCount+gapPadding;
    tileHeight = tileDepth;
    posY=0;
    posX=187-tileWidth+gapPadding;
    for(i=0; i<tileCount; i++){
      if(posX>166){
        returnArray.unshift([posX,posY,tileWidth,tileHeight,"left bottom",0]);
      } else {
        returnArray.unshift([posX,posY,tileWidth,tileHeight,getPosition(),0]);
      }
      posX=posX-tileWidth+gapPadding;
    }
    // if there aren't enough tiles
    const diff = count-returnArray.length;
    if(diff>0){
      for(let i=0; i<diff; i++){
        returnArray.push([93.5,75,1,1,"center center",0]);
      }
    }
    return returnArray;
  };