import React, { useEffect } from "react";

import axios from "axios";

import { baseURL } from "../../utilities/constant";

import downloadN from "../../assets/images/admin/download-n.png"; 

const EventTitleBar = ({ eventName, setEventName, refreshTiles }) => {

    useEffect(() => {
        const myGet= "/get/events";
        <div className="select-none cursor-pointer" onMouseUp={() => eventsOpen("")} >All Events</div>
        axios.get(`${baseURL}${myGet}`).then((res) => {
            eventListUpdate(res.data);
        });
      }, []);

    const eventsOpen=(eventSelected)=>{
        setEventName(eventSelected);
    }
    function eventsOpenClick(event){
        console.log(event.target.getAttribute("data-open"));
        eventsOpen("sample");
    }
    const eventsShow=()=>{
        /*
        return (
            <>
            <div className="select-none cursor-pointer"
                onMouseUp={() => eventsOpen("")} >All Events</div>
                {window.eventsArr.map((item, index) => (
                    <div className="select-none cursor-pointer"
                        key={index} onMouseUp={() => eventsOpen(item)} >{item}</div>
                ))}
            </>
        );  
        */
    }

    const openShow=()=>{
        return (
            <>
            <a href={"../../"+eventName} target="_blank" rel="noopener noreferrer">Drawing Tool</a>
            <a href={"../../Display/"+eventName} target="_blank" rel="noopener noreferrer">Main Event</a>
            </>
        );    
    }

    const eventListUpdate=(data)=>{
        let element = document.getElementById("event-list-dropdown");
        element.innerHTML = '';
        let child = document.createElement('div');
        child.classList.add('select-none','cursor-pointer');
        child.innerHTML = 'All Events';
        element.appendChild(child);
        child.onclick = function () {
            eventsOpen("");
        };
        for(let i=0; i<data.length; i++){
            //console.log("eventListUpdate: data[i].event="+data[i].event);
            child = document.createElement('div');
            child.classList.add('select-none','cursor-pointer');
            child.innerHTML = data[i].event;
            element.appendChild(child);
            child.onclick = function () {
                eventsOpen(data[i].event);
            };
        }
    }
    return (<>
        <div className="w-full bg-abt-blue-dark">
            <div className="mx-2">
                <div className="container flex mx-auto">
                    <div className="tooltip shrink w-[40px] h-[40px] my-auto btn"
                        style={{ backgroundImage:`url(${downloadN})`}}
                        onClick={refreshTiles}
                    >
                        <span className="tooltiptext text-sm">Download data</span>
                    </div>
                    <div className="grow mb-1 text-5xl font-semibold text-white py-2">
                    {eventName===""?window.allEvents:eventName}
                    </div>
                    <span className="dropdown mt-7">
                        <button className="dropbtn">Open</button>
                        <div className="dropdown-content">
                            {openShow()}
                        </div>
                    </span>
                    <span className="dropdown mt-7 mr-6">
                        <button className="dropbtn">Select Event</button>
                        <div id="event-list-dropdown" className="dropdown-content">
                            {eventsShow()}
                        </div>
                    </span>
                </div>
            </div>
        </div>
    </>);
  };
  
  export default EventTitleBar;