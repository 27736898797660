import React from "react";
import { useEffect, useRef } from "react";
import axios from "axios";

import { baseURL } from "../../utilities/constant";

import {
  BrowserRouter as Router,
  useParams,
} from "react-router-dom";

import drawOnCanvas from "../../utilities/drawOnCanvas";
import getNumberFromStyle from "../../utilities/getNumberFromStyle";
import getRandom from "../../utilities/getRandom";
import arraySearch from "../../utilities/arraySearch";

import scaleImage from '../../utilities/scaleImage';
import scaleTilesFore from '../../utilities/scaleTilesFore';
import tileAnimation from "../../utilities/tileAnimation.js";
import tileAnimationSort from "../../utilities/tileAnimationSort";
import tileMaskBuild from '../../utilities/tileMaskBuild';

import backgroundJpg from "../../assets/images/background.jpg"; 
import pausedPng from "../../assets/images/paused.png"; 

import abbottSvg from "../../assets/images/abbott.svg"; 

import './DisplayPage.css';


const DisplayPage = () => {
  const { eventUrl } = useParams();
  let tileAnimationArr=null;
  const handleResize = () => {
    scaleImage();
    tileAnimationArr = scaleTilesFore(tileAnimationArr);
  }

  useEffect(() => {
    window.addEventListener("keyup", (event) => {
      event.preventDefault();
      executeKeyUp(event);
    });
    scaleImage();
    tileAnimationArr = scaleTilesFore(tileAnimationArr);
  }, []);
  window.addEventListener("resize", handleResize, false);

  const executeKeyUp = (event) => {
    if((new Date()-keyupDate)>100){
      console.log("eventKey=\""+event.key+"\"");
      keyupDate=new Date();
      if(event.key.toLowerCase()==="arrowup"){
        isKeyControl=true;
      }
      if(isKeyControl){
        switch(event.key.toLowerCase()) {
          case " ":
            startStopToggle();
            break;
          case "enter":
            startStopToggle();
            break;
            case "arrowright":
              TagLineShow();
              break;
            case "arrowleft":
              startStopToggle();
              let element = document.getElementById("tag-text");
              element.style.opacity=0;
          
              element = document.getElementById("logo-background");
              element.style.opacity=0;
              break;
            case "arrowdown":
              isKeyControl=false;
              break;
            case "w":
              //startStopToggle();
              break;
        default:
        }
      }
      if(event.key==="`"){
        console.log("******************************************");
        console.log("*** isPlaying="+isPlaying);
        console.log("*** isPinging="+isPinging);
        console.log("*** isGrow="+isGrow);
        console.log("*** deadTime="+deadTime);
        console.log("tilesArr.length="+tilesArr.length);
        const dateCurrent = new Date();
        console.log("*** dateStart="+dateStart);
        console.log("*** Running time="+Math.floor(((dateCurrent-dateStart)/1000)/60)+" minutes");
        console.log("*** Running time="+Math.floor(((dateCurrent-dateStart)/1000)/60)+" minutes");
        console.log("******************************************");
      }
    }
  }
console.log("DisplayPage: Refreshed");
  let eventId = eventUrl===undefined?window.allEvents:eventUrl;
  let dateStart = new Date();
  let keyupDate = new Date();
  let isPlaying=false;
  let isKeyControl=false;
  let pingInterval=null;
  let animationInterval=null;
  let isPinging=true; // while true program won't ping the database
  
  let isGrow = arraySearch(window.animationType.split(","), "grow");
  let growItem=0;

  let animationComplete=false;
  let deadTime=0;

  let tilesArr=[];
  let locationArray=tileMaskBuild(window.userCount);
  //let tileSize=0;

  const updateTiles=(tempArr)=>{
    let i=0;
    let image=null;
    //console.log(tempArr[0].stringify());
    //i++;
    if(tilesArr.length<tempArr.length){
      for(i=tilesArr.length; i<tempArr.length; i++){
        tilesArr.push(tempArr[i].tile);
        image = drawOnCanvas(document.getElementById("drawCanvas"), tempArr[i].tile);
        if(document.getElementById("backTile_"+i)){
          document.getElementById("backTile_"+i).style.backgroundImage = "url('" + image + "')";
          document.getElementById("backTile_"+i).style.backgroundColor = "white";
        };
      }
    }
  }

  const TagLineShow=()=>{
    let element = document.getElementById("abbott-mask");
    const maskRect = element.getBoundingClientRect();
    element = document.getElementById("tag-text");
    element.style.width=((maskRect.right-maskRect.left)*2)+"px";
    element.style.left=(maskRect.left-((maskRect.right-maskRect.left)/2))+"px";
    element.style.opacity=1;

    element = document.getElementById("logo-background");
    element.style.opacity=1;

  }
  
  const setAnimation=(animationTypeNew)=>{
    if(animationTypeNew!==window.animationType){
      window.animationType=animationTypeNew;
      const animationArr = window.animationType.split(",");
      isGrow = arraySearch(window.animationType.split(","), "grow");
      let elements = Array.from(document.getElementsByClassName('backTile'));
      let i=0;
      if(animationArr[0]==="none"){
        elements.forEach(element => {
          element.style.opacity = 1;
        });
        elements = Array.from(document.getElementsByClassName('foreTile'));
        elements.forEach(element => {
          element.style.opacity = 0;
        });
        for(i=0; i<locationArray.length; i++){ 
          locationArray[i][5]=2;
        }
        for(i=0; i<tileAnimationArr.length; i++){ 
          tileAnimationArr[i][1]=0;
        }
        deadTime=7000; // (24*60)*5=7200

      }
    }
  }

  const pingDatabase=()=>{
    if(!isPinging){
      isPinging=true;
      let count = tilesArr.length<1?1:tilesArr.length;
      const myGet= "/get/"+eventId+"/"+count;
console.log("pingDatabase(): isPinging");
      axios.get(`${baseURL}${myGet}`).then((res) => {
        //console.log("res.data[0].status.toLowerCase()="+res.data[0].status.toLowerCase());

        if(res.data[0].status.toLowerCase()==="paused"){
          isPlaying=false;
        } else {
          isPlaying=true;
console.log("res.data.length="+res.data.length);
          updateTiles(res.data.slice(1, window.userCount+1));
        }
        //pingTimerStop(); // for testing we'll stop after one hit
        
        setAnimation(res.data[0].animation===undefined?"full":res.data[0].animation.toLowerCase());
        isPinging=false;
      }).catch(function (error) {
        //console.log(error.toJSON());
        isPinging=false;
      });
    }
  }

  const TileAnimate=()=>{
    let i=0;
    let tempArr=[];
    // check if there is an available spot to animate
    for(i=0; i<tileAnimationArr.length; i++){
      if(tileAnimationArr[i][0]===-1){
        tempArr.push(i);
      }
    }
    if(Math.floor(Math.random() * 100)>50||tempArr.length>3){// 1 in 2 chance of a card being displayed
      let tileAnimate=0;
      let tileMask=0;

      tempArr=[];
      // check if there is an available spot to animate
      for(i=0; i<tileAnimationArr.length; i++){
        if(tileAnimationArr[i][0]===-1){
          tempArr.push(i);
        }
      }
      if(!isPlaying){
        tempArr.push(null);
      }

      if(isPlaying){
        if(tempArr.length>0&&tilesArr.length>0){
          tileAnimate=getRandom(tempArr);
          tempArr=[];
          for(i=0; i<tilesArr.length; i++){
            if(locationArray[i][5]===0){
              tempArr.push(i);
            }
          }
    // ***********************************************
          // check if there is a tile to add
          if(tempArr.length>0){
            tileMask=getRandom(tempArr);
            locationArray[tileMask][5]=1;
            tileAnimationArr[tileAnimate][0]=tileMask; // tileArr[Position]
            tileAnimationArr[tileAnimate][1]=1; // status 1=paused(for reading)
            //tileAnimationArr[tileAnimate][2]=0; // reset animation step
            tileAnimationArr[tileAnimate][2]=Math.floor(Math.random() * 50);
            let image = drawOnCanvas(document.getElementById("drawCanvas"), tilesArr[tileMask]);
            if(document.getElementById("foreTile_"+tileAnimate)){
              let tileFore = document.getElementById("foreTile_"+tileAnimate);
              tileFore.style.backgroundImage = "url('" + image + "')";
              tileAnimationSort(null);
              tileFore.style.display = "block";
            };
          }
        }
      }
    }
  }

  const pingTimer=()=>{
    pingDatabase();
    TileAnimate();
  }
  /*
  const pingTimerStop=()=>{
    console.log("pingTimerStop()");
    clearInterval(pingInterval);
  }

  function animationTimerStop() {
    clearInterval(animationInterval);
  }
  */
 
  const animationTimer=()=>{
    if(isPlaying){
      //if(animationType==="full"){
        tileAnimationArr=tileAnimation(tileAnimationArr);
      //}
      let i=0;
      let isDead=true;
      for(i=0; i<tileAnimationArr.length; i++){
        if(tileAnimationArr[i][1]>0){
          isDead=false;
        }
      }
      let tileBack;
      
      if(!animationComplete){
        let checkComplete=true;
        for(i=0; i<window.userCount; i++){ 
          tileBack = document.getElementById("backTile_"+i);
          if(Number(tileBack.style.opacity)!==1){
            checkComplete=false;
          }
        }
        if(!animationComplete){     
          animationComplete=checkComplete;
          if(animationComplete){ // if all tiles are shown
            TagLineShow();
          }
        }
      }
      
      if(isDead){ // if nothing is animating
        deadTime++;
        //console.log("deadTime="+deadTime);
        for(i=0; i<locationArray.length; i++){ 
          if(locationArray[i][5]!==2){
            isDead=false;
          }
        }
        
        /* XXXXXXXXXXXXXXX */

//console.log("tilesArr.length="+tilesArr.length);
        if(tilesArr.length>0){
//console.log("deadTime="+deadTime+" animationComplete="+animationComplete);
          if(deadTime>(24*60)*5||(isGrow)){ // if deadTime is > X seconds do something...
            //if(deadTime>50){ // if deadTime is < X seconds do something...
            let item=0; // just use the first one for this
            //const tileNumber=Math.floor(Math.random() * tilesArr.length);
            growItem++;
            growItem=growItem<tilesArr.length?growItem:0;
            const tileNumber=growItem;

//console.log("screensaver: tileNumber="+tileNumber);
            let image = drawOnCanvas(document.getElementById("drawCanvas"), tilesArr[tileNumber]);
            if(document.getElementById("foreTile_"+item)){
//console.log("screensaveer: 1");
              if(document.getElementById("backTile_"+item)){
                tileBack = document.getElementById("backTile_"+tileNumber);
                if(String(tileBack.style.opacity)==="1"){
                  deadTime=7000; // (24*60)*5=7200
                  const tileFore = document.getElementById("foreTile_"+item);
                  const tileBackWidth = getNumberFromStyle(tileBack.style.width);
                  const tileBackHeight = getNumberFromStyle(tileBack.style.height);
                  tileFore.style.backgroundImage = "url('" + image + "')";
                  const sideSize=Number(tileBackWidth<tileBackHeight?tileBackWidth:tileBackHeight);
                  tileFore.style.width = (sideSize)+"px";
                  tileFore.style.height = (sideSize)+"px";
                  const backRect=tileBack.getBoundingClientRect();
                  tileFore.style.left = ((backRect.left)-((sideSize-tileBackWidth)/2))+"px";
                  tileFore.style.top = ((backRect.top)-((sideSize-tileBackHeight)/2))+"px";
                  //tileFore.style.display = "block";
                  tileFore.style.opacity = 1;
                  tileAnimationArr[item][0]=tileNumber;
                  tileAnimationArr[item][1]=5;
                  tileAnimationArr[item][2]=0; // reset steps  
                } else {
                  growItem=0;
                }
              }
            };
            //Math.floor(Math.random() * tilesArr.length)
          }
        }
      } else {
        deadTime=0;
      }
    }
  }

  const startStopToggle=()=>{
    console.log("startStopToggle()");
    const checkDate = new Date();
    if((checkDate-dateStart)>10){// making sure double click doesn't turn it on and off
      const element = document.getElementById("paused-image");
      if(Number(element.style.opacity) === 0){
        element.style.opacity=1;
        isPinging=true;
        //pingTimerStop();
      } else {
        element.style.opacity=0;
        isPinging=false;
        //pingTimerStop();
        //pingInterval = setInterval(pingTimer, (1000*5));
      }
      isPlaying=isPlaying?false:true;
    }
    console.log("isPlaying="+isPlaying);
  }

  const initTilesFore=()=>{
    let tileArr=[];
    tileAnimationArr = scaleTilesFore(tileAnimationArr);
    for(let i=0; i<tileAnimationArr.length; i++){
      tileArr.push(<div id={"foreTile_"+i}
        key={"foreTile_"+i}
        className="tile foreTile tile-border style-transition"
        style={{left:tileAnimationArr[i][3]+"px",
          top:tileAnimationArr[i][4]+"px",
          width:tileAnimationArr[i][5]+"px",
          height:tileAnimationArr[i][5]+"px",
          backgroundColor:"white",
          backgroundSize: "contain",
          opacity:0
        }}
    ></div>);
    }
    return tileArr;
  }

  const initTilesBack=()=>{
    let tileArr=[];
    for(let i=0; i<locationArray.length; i++){
      tileArr.push(<div id={"backTile_"+i}
        key={"backTile_"+i}
        className="image-scale tile backTile"
        data-left={locationArray[i][0]}
        data-top={locationArray[i][1]}
        data-width={locationArray[i][2]}
        data-height={locationArray[i][3]}
        style={{
          left:(locationArray[i][0])+"px",
          top:(locationArray[i][1])+"px",
          width:(locationArray[i][2])+"px",
          height:(locationArray[i][3])+"px",
          backgroundPosition:locationArray[i][4],
          display:"block"
        }}
    ></div>);
    }
    return tileArr;
  }

  document.body.classList.add("overflow-hidden");

  if(pingInterval===null){
    pingInterval = setInterval(pingTimer, (1000*5));
  }

  if(animationInterval===null){
    animationInterval = setInterval(animationTimer, 40);
  }

  return (
    <div className="overflow-hidden">
      <div className="background cursor-none" style={{ backgroundImage:`url(${backgroundJpg})` }}>
        <div id="paused-image" className="image style-transition" style={{position: "absolute",
          top: "0",
          left: "0",
          backgroundImage:`url(${pausedPng})`,
          width:"50px",
          height:"50px",
          display: "block"}}></div>
        <div id="tag-text" className="text-white text-center box-content style-transition image-scale"
          data-left="3"
          data-top="60"
          data-width="300"
          data-height="50"
          data-fontsize="13"
          data-letterspacing="3"
          style={{position: "absolute",
            fontFamily: "Arial Rounded MT",
            opacity: "0"
          }}>
          THIS IS ABBOTT LEADING
        </div>
        <div id="abbott-mask" className="abbott-mask image-scale"
          data-left="60"
          data-top="90"
          data-width="186"
          data-height="150"
          style={{
            WebkitMaskImage: "url("+abbottSvg+")",
            maskImage: abbottSvg,
            WebkitMaskRepeat: "no-repeat",
            maskRepeat: "no-repeat"  
          }}
        >
          <div id="logo-background" className="style-transition" style={{position: "absolute",
            backgroundColor:"white",
            width:"100%",
            height:"100%",
            opacity: "0"}}>
          </div>
          {initTilesBack()}
        </div>
    {initTilesFore()}
        <div id="start-tiles"
          style={{
            position:"absolute",
            width:"100%",
            height:"100%",
            top:"0",
            left:"0",
            zIndex: "9000"}}
          onMouseDown={() => startStopToggle()}
        ></div>
        <canvas
          id="drawCanvas"
              width="800px"
              height="800px"
              style={{ postion: "absolute", backgroundColor:"white", display: "none"}}
        />
      </div>
    </div>
  )
}

export default DisplayPage