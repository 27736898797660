import getNumberFromStyle from "./getNumberFromStyle";
export default function tileAnimationSort(tileSize) {
    let tiles = document.getElementsByClassName('foreTile');
    for (let i = 0; i < tiles.length; i++){
        tiles[i].style.zIndex=Math.round(getNumberFromStyle(tiles[i].style.width));

    }
    /*
    let mySizeArr=[];
    let myCount=0;
    mySizeArr.push(0);
    let mySize = 0;
    let myIndexZ = 10;
    let iTiles;
    let iSizes;
    for (iTiles = 0; iTiles < tiles.length; iTiles++){
        mySize = getNumberFromStyle(tiles[iTiles].style.width);
        myCount=mySizeArr.length;
        for (iSizes = 0; iSizes < myCount; iSizes++){
            if (mySize < getNumberFromStyle(tiles[mySizeArr[iSizes]].style.width)) {
                mySizeArr.splice(iSizes, 0, iTiles);
            }
        }
    }
    console.log("------------------------------------------");
    for (iSizes = 0; iSizes < mySizeArr.length; iSizes++){
        if(getNumberFromStyle(tiles[mySizeArr[iSizes]].style.width)<tileSize){
console.log("tiles[mySizeArr["+iSizes+"]].style.width="+tiles[mySizeArr[iSizes]].style.width);
            tiles[mySizeArr[iSizes]].style.zIndex = myIndexZ;
            myIndexZ++;
        }
    }
    */
}