import React, { useRef } from 'react';

import drawOnCanvas from "../../utilities/drawOnCanvas";
import preventDefault from '../../utilities/preventDefault';
import AlignLeft from '../../assets/icons/AlignLeft';
import AlignRight from '../../assets/icons/AlignRight';
import AlignCenter from '../../assets/icons/AlignCenter';

const TextEditor = () => {
    const fontFamilyRef = useRef();
    const fontFamilyDisplayArr=["Modern","Cursive","Formal"];
    const fontFamilyArr=["Arial,Sans-serif","Brush Script MT,Cursive","Times-Roman,Serif"];

    const alignToggle=()=>{
        const linesArray=window.canvasData.split("|");
        const textStyles = linesArray[1].split(",");
        const alignLeft = document.getElementById("align-left");
        const alignRight = document.getElementById("align-right");
        const alignCenter = document.getElementById("align-center");
        alignLeft.classList.remove("bg-abt-blue-dark");
        alignRight.classList.remove("bg-abt-blue-dark");
        alignCenter.classList.remove("bg-abt-blue-dark");
        switch(textStyles[2]){
            case "center":
                alignCenter.classList.add("bg-abt-blue-dark");
                break;
            case "right":
                alignRight.classList.add("bg-abt-blue-dark");
                break;
                default:
                    alignLeft.classList.add("bg-abt-blue-dark");
        }
    }
    const textEditorToggle=(value)=>{
        fontFamilyToggle("fontFamilyChild", false);
        const textEditor = document.getElementById("TextEditor");
        const tileText = document.getElementById("tile_text");
        const linesArray=window.canvasData.split("|");
        const textStyles = linesArray[1].split(",");
        tileText.style.textAlign=textStyles[2];
        alignToggle();
        textEditor.style.display=value?"block":"none";
    }
    const textSubmit=()=>{
        if(document.getElementById("tile_text") !== null){
            const value = document.getElementById("tile_text").value;
            switch(true){
                case (value.length>200):
                    alert("Text is too long.");
                    break;
                default:
                    const linesArray=window.canvasData.split("|");
                    const result = value.split("|").join(window.itemDelimiter);// any "|" in the string would break the array
                    linesArray[0]=result;
                    window.canvasData=linesArray.join("|");
                    textEditorToggle(false);
                    //// window.canvasData="|,,,|"; // this is a string for a card: "TEXT|FONT,FONT_SIZE,TEXT_ALIGN,FONT_COLOR|x1,y1,x2,y2,x3,y3|x1,y1,x2,y2,x3,y3" 
                    drawOnCanvas(document.getElementById("drawCanvas"));
            }
        }
    }
    const textAlignSet=(align)=>{
        fontFamilyToggle("fontFamilyChild", false);
        const tileText = document.getElementById("tile_text");
        const linesArray=window.canvasData.split("|");
        let textStyles = linesArray[1].split(",");
        textStyles[2] = align.toLowerCase();
        linesArray[1]=textStyles.join(",");
        window.canvasData=linesArray.join("|");
        tileText.style.textAlign=align;
        alignToggle();
    }

    const fontFamilyChange=(index)=>{
        const tileText = document.getElementById("tile_text");
        const linesArray=window.canvasData.split("|");
        let textStyles = linesArray[1].split(",");
        textStyles[1] = index;
        linesArray[1]=textStyles.join(",");
        window.canvasData=linesArray.join("|");
        tileText.style.fontFamily=fontFamilyArr[index];
        fontFamilyRef.current.style.fontFamily=fontFamilyArr[index];
        fontFamilyRef.current.value=fontFamilyDisplayArr[index];
    }
    const fontFamilyToggle=(elementId,isShow)=>{
console.log("fontFamilyToggle="+isShow);
        if(document.getElementById(elementId)){
            const element=document.getElementById(elementId);
            if(isShow==null){
                element.style.visibility=element.style.visibility==="visible"?"hidden":"visible";
            } else {
                element.style.visibility=isShow?"visible":"hidden";
            }
        };
    }
    const inputFocus=(elementId)=>{
        fontFamilyToggle("fontFamilyChild", false);
        if(document.getElementById(elementId)){
            document.getElementById(elementId).focus();
        };
    }
    const fontFamilyShow=()=>{
        //fontFamilyArr
        const buttonClassName="select-none cursor-pointer dd-child-button";
        return (
            <div id="fontFamilyChild" className="dropdown-child" style={{left:"6px", top:"38px"}}>
                <ul className="absolute">
                    {fontFamilyDisplayArr.map((item, index) => (
                        <li key={index}>
                            <div style={{fontFamily:fontFamilyArr[item]}}
                                onMouseUp={() => fontFamilyChange(index)}
                                onTouchEnd={() => fontFamilyChange(index)}
                                className={buttonClassName} 
                            >{item}</div>
                        </li>
                    ))}
                </ul>
            </div>
        );    
    }

    const inputKeyCheck = (keyCode) => {
        if(document.getElementById("tile_text")){
            switch(keyCode){
                case 13:
                    const element=document.getElementById("tile_text");
                    element.blur();
                    break;
                default:
                    return null;
            }
        }
    }

    return (<>
        <div id="TextEditor" style={{display:"none"}}>
            <div className="modal touch-none"
                onClick={() => textEditorToggle(false)}
                //onTouchEnd={() => textEditorToggle(false)}
                >
                <div className="modal-text-editor w-full"
                    onClick={preventDefault}>
                    <div className="flex close float-right">
                        <div className="square-button justify-center items-center bg-abt-blue-dark"
                            onMouseUp={() => textEditorToggle(false)}
                            onTouchEnd={() => textEditorToggle(false)}
                            style={{fontSize: "52px"}}
                        >&times;</div>
                    </div>
                    <textarea id="tile_text" name="tile_text" rows="6"
                    className="tile-text mt-10"
                        placeholder="Write your commitment..."
                        onTouchEnd={() => inputFocus("tile_text")}
                        onKeyDown={(event) => inputKeyCheck(event.keyCode)}

                    />
                    <div className="flex mt-10">
                        <span className="dropdown-parent tile-text"
                            onTouchEnd={() => fontFamilyToggle("fontFamilyChild", null)}
                        >
                            <input className="dd-parent-button"
                                ref={fontFamilyRef}
                                style={{fontFamily:fontFamilyArr[0]}}
                                defaultValue={fontFamilyDisplayArr[0]}
                            ></input>
                            {fontFamilyShow()}
                        </span>
                    </div>

                    <div className="flex mt-10">
                        <div className="flex flex-auto items-center">
                            <div id="align-left" className="square-button flex justify-center items-center bg-abt-blue bg-abt-blue-dark mr-7"
                                onMouseUp={() => textAlignSet("left")}
                                onTouchEnd={() => textAlignSet("left")}
                                style={{fontSize: "48px"}}>
                                <AlignLeft />
                            </div>
                        </div>
                        <div className="flex-auto">
                            <div id="align-center" className="square-button flex  justify-center items-center bg-abt-blue mr-7"
                                onMouseUp={() => textAlignSet("center")}
                                onTouchEnd={() => textAlignSet("center")}
                                style={{fontSize: "48px"}}>
                                <AlignCenter />
                            </div>
                        </div>
                        <div className="flex-auto">
                            <div id="align-right" className="square-button flex  justify-center items-center bg-abt-blue mr-7"
                                onMouseUp={() => textAlignSet("right")}
                                onTouchEnd={() => textAlignSet("right")}
                                style={{fontSize: "48px"}}>
                                <AlignRight />
                            </div>
                        </div>
                        <div className="flex-auto">
                            <div className="square-button save-btn float-right"
                                onClick={() => textSubmit()}
                                onTouchEnd={() => textSubmit()}
                            ></div>
                        </div>
                    </div>
                </div>
            </div> 
            <div id="checkText" style={{position:"absolute", visibility: "hidden", height: "auto", width: "auto", whiteSpace: "nowrap"}}></div>
        </div>
    </>);
}
export default TextEditor;